import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import { defaultErrorMessage, IDbUser } from "./interfaces";

const initialState: {
  user: IDbUser | null;
  isLoading: boolean;
  isLoadingParents: boolean;
  errorMessage: string;
  allUsers: IDbUser[];
} = {
  user: null,
  isLoading: true,
  isLoadingParents: false,
  errorMessage: "",
  allUsers: [],
};

export const getUser = createAsyncThunk<IDbUser[], void, { rejectValue: string }>(
  "users/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      console.log("calling db.....");
      const ret = await Api.getUsers();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const getAllUsers = createAsyncThunk<IDbUser[], void, { rejectValue: string }>(
  "users/getAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const ret = await Api.getAllUsers();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const createUser = createAsyncThunk<IDbUser, IDbUser, { rejectValue: string }>(
  "users/postUser",
  async (_data: IDbUser, { rejectWithValue }) => {
    try {
      const ret = await Api.createUser(_data);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const editUser = createAsyncThunk<IDbUser, IDbUser, { rejectValue: string }>(
  "users/editUser",
  async (_data: IDbUser, { rejectWithValue }) => {
    try {
      const ret = await Api.editUser(_data);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const deleteUser = createAsyncThunk<IDbUser[], string, { rejectValue: string }>(
  "users/deleteUser",
  async (userId, { rejectWithValue }) => {
    try {
      const ret = await Api.deleteUser(userId);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const adminUpdateUserAccountType = createAsyncThunk<IDbUser[], IDbUser, { rejectValue: string }>(
  "users/adminUpdateUserAccountType",
  async (_data: IDbUser, { rejectWithValue }) => {
    try {
      const ret = await Api.adminUpdateUserAccountType(_data);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);
export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reset: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      console.log("got user....");
      state.isLoading = false;
      state.user = payload[0];
    });
    builder.addCase(getUser.pending, (state, _) => {
      console.log("pending user....");
      state.isLoading = true;
    });
    builder.addCase(getUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });
    //
    builder.addCase(createUser.fulfilled, (state, action: PayloadAction<IDbUser>) => {
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(createUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
    });

    // editUser
    builder.addCase(editUser.fulfilled, (state, { payload }) => {
      state.user = payload;
    });

    builder.addCase(editUser.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
    });

    // delete user account
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.isLoadingParents = false;
      state.allUsers = payload;
    });

    builder.addCase(deleteUser.pending, (state, _) => {
      state.isLoadingParents = true;
    });

    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
      state.isLoadingParents = false;
    });

    // Get users
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.isLoadingParents = false;
      state.allUsers = payload;
    });
    builder.addCase(getAllUsers.pending, (state, _) => {
      state.isLoadingParents = true;
    });
    builder.addCase(getAllUsers.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingParents = false;
      console.log(state.errorMessage);
    });
    // Admin update user type
    builder.addCase(adminUpdateUserAccountType.fulfilled, (state, { payload }) => {
      state.isLoadingParents = false;
      state.allUsers = payload;
    });
    builder.addCase(adminUpdateUserAccountType.pending, (state, _) => {
      state.isLoadingParents = true;
    });
    builder.addCase(adminUpdateUserAccountType.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingParents = false;
      console.log(state.errorMessage);
    });
  },
});
export const isLoading = (state: RootState) => state.users.isLoading;
export const isLoadingParents = (state: RootState) => state.users.isLoadingParents;
export const selectUser = (state: RootState) => state.users.user;
export const allUsers = (state: RootState) => state.users.allUsers;
export const errorMessage = (state: RootState) => state.users.errorMessage;
export const { reset } = userSlice.actions;

export default userSlice.reducer;
