import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import { defaultErrorMessage, IStaffRoasterBooking, IStaffRoasterMonthBooking } from "./interfaces";

const initialState: {
  errorMessage: string;
  staffBooking?: IStaffRoasterBooking[];
  currentMonthHolidays?: string[];
  isLoadingCell: boolean;
} = {
  isLoadingCell: false,
  errorMessage: "",
  staffBooking: [],
  currentMonthHolidays: [],
};

export const updateStaffRoaster = createAsyncThunk<
  IStaffRoasterMonthBooking,
  { staffId: string; date: string; isSignedIn: boolean },
  { rejectValue: string }
>("updateStaffRoaster", async ({ staffId, date, isSignedIn }, { rejectWithValue }) => {
  try {
    const ret = await Api.updateStaffRoaster(staffId, date, isSignedIn);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const getStaffRoaster = createAsyncThunk<IStaffRoasterMonthBooking, void, { rejectValue: string }>(
  "getStaffRoaster",
  async (_, { rejectWithValue }) => {
    try {
      const ret = await Api.getStaffRoaster();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const staffRoasterSlice = createSlice({
  name: "staffRoaster",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get staff roaster
    builder.addCase(getStaffRoaster.fulfilled, (state, { payload }) => {
      state.isLoadingCell = false;
      state.staffBooking = payload.bookings;
      state.currentMonthHolidays = payload.currentMonthHolidays;
    });
    builder.addCase(getStaffRoaster.pending, (state, _) => {
      state.isLoadingCell = true;
    });
    builder.addCase(getStaffRoaster.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingCell = false;
      console.log(state.errorMessage);
    });

    builder.addCase(updateStaffRoaster.fulfilled, (state, { payload }) => {
      state.isLoadingCell = false;
      state.staffBooking = payload.bookings;
      state.currentMonthHolidays = payload.currentMonthHolidays;
    });
    builder.addCase(updateStaffRoaster.pending, (state, _) => {
      state.isLoadingCell = true;
    });
    builder.addCase(updateStaffRoaster.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingCell = false;
      console.log(state.errorMessage);
    });
  },
});

export const staffBooking = (state: RootState) => state.staffRoaster.staffBooking;
export const currentMonthHolidays = (state: RootState) => state.staffRoaster.currentMonthHolidays;
export const isLoadingCell = (state: RootState) => state.staffRoaster.isLoadingCell;

export const errorMessage = (state: RootState) => state.staffRoaster.errorMessage;

export default staffRoasterSlice.reducer;
