import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { bankHolidays, getStudents, isLoading, studentsData } from "../../slices/booking";
import { lazy, Suspense, useContext, useEffect, useMemo, useState } from "react";
import PageLoadingSpinner from "../PageLoadingSpinner";
import { GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { IStudentData } from "../../slices/interfaces";
import { deleteTerm, getTerms, terms } from "../../slices/term";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { BookingContext } from "../../modules/context";
import Fab from "@mui/material/Fab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { CustomizedDataGrid } from "../styledComponent";
import { showAlert } from "../../slices/alert";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { resetPupilBooking } from "../../slices/booking";
import { format } from "date-fns";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";
const TermTimeSheet = lazy(() => import("./TermTimeSheet"));
const PupilBooking = lazy(() => import("./PupilBooking"));
const CurrentMonthBooking = lazy(() => import("./CurrentMonthBooking"));

const fabBox = {
  backgroundColor: "#283e4a",
  textTransform: "capitalize",
  marginLeft: "10px",
  paddingRight: "10px",
  zIndex: 1,
};

const BreakfastClubAttendance = lazy(() => import("./BreakfastClub"));
const StaffRoaster = lazy(() => import("./StaffRoaster"));

export function AdminBooking() {
  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();
  const students = useAppSelector(studentsData);
  const [openModal, setOpenSchoolModal] = useState<boolean>(false);
  const savedTermHolidays = useAppSelector(terms);
  const publicHolidays = useAppSelector(bankHolidays);
  const [editTerm, setEditTerm] = useState<boolean>(false);
  const [termId, setTermId] = useState<string>("");
  const [isDeleteClicked, setIsDeleteClick] = useState<boolean>(false);
  const [openPupilModal, setOpenPupilModal] = useState<boolean>(false);
  const [pupilId, setPupilId] = useState<string>("");
  const [openCurrentMonthBooking, setCurrentMonthBooking] = useState<boolean>(false);
  const [openMonthBreakfastClubBooking, setOpenMonthBreakfastClubBooking] = useState<boolean>(false);
  const [openRoaster, setOpenRoaster] = useState<boolean>(false);

  const { setSelectedOffDays } = useContext(BookingContext);

  logEvent(analytics, "wAdminBooking", {
    firebase_screen_class: "wAdminBooking",
    page_title: "AdminBooking",
  });

  const groupedHolidays = useMemo(() => {
    const publicHolidayStartYears: number[] = [];
    publicHolidays.map(
      (holiday) =>
        publicHolidayStartYears.indexOf(new Date(holiday.date).getFullYear()) === -1 &&
        publicHolidayStartYears.push(new Date(holiday.date).getFullYear())
    );
    const groupedPublicHolidays: Record<string, any> = {};
    for (let startYear of publicHolidayStartYears) {
      const holidayData = {
        startYear,
        holidays: publicHolidays.filter((holiday) => {
          const termTimeStart = new Date(startYear, 8);
          const endYear = startYear + 1;
          const termTimeEnd = new Date(endYear, 8, 0);
          const holidayDate = new Date(holiday.date);
          return holidayDate >= termTimeStart && holidayDate <= termTimeEnd;
        }),
      };
      groupedPublicHolidays[startYear] = holidayData;
    }
    return groupedPublicHolidays;
  }, [publicHolidays]);

  useEffect(() => {
    dispatch(getStudents());
    dispatch(getTerms());
  }, [dispatch]);

  const handleClose = (): void => {
    setOpenSchoolModal(!openModal);
    setEditTerm(false);
    setTermId("");
    setSelectedOffDays([]);
  };

  const handleCurrentMonthBookingClose = (): void => {
    setCurrentMonthBooking(!CurrentMonthBooking);
  };

  const handlePupilModalClose = (): void => {
    setOpenPupilModal(!openPupilModal);
    setPupilId("");
    dispatch(resetPupilBooking());
  };

  const handleBreakfastClubAttendance = (): void => {
    setOpenMonthBreakfastClubBooking((prev) => !prev);
  };

  const handleCloseRoaster = (): void => {
    setOpenRoaster((prev) => !prev);
  };

  const openPupilBooking = (id: string) => {
    setPupilId(id);
    setOpenPupilModal(true);
  };

  const rows: GridRowsProp<IStudentData> = students;
  const columns: GridColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      filterable: true,
    },
    {
      field: "lastName",
      headerName: "Surname",
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: "school",
      headerName: "School",
      sortable: false,
      filterable: false,
      minWidth: 600,
      renderCell: (params) => {
        return (
          <Grid justifyContent={"space-between"} container alignItems={"center"}>
            <Grid item xs={8}>
              <Typography variant={"inherit"}>{params.row.school.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="success"
                disabled={!params.row.isActive}
                sx={{
                  p: 0.2,
                  height: "25px",
                  fontSize: "13px",
                }}
                onClick={() => openPupilBooking(params.row._id)}
              >
                Booking
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const editRowTerm = async (rowId: string) => {
    setEditTerm(!editTerm);
    setOpenSchoolModal(true);
    setTermId(rowId);
  };
  const deleteRowTerm = async (rowId: string) => {
    setTermId(rowId);
    setIsDeleteClick(true);
    const ret = await dispatch(deleteTerm(rowId));
    if (!deleteTerm.fulfilled.match(ret)) {
      dispatch(
        showAlert({
          message: `${ret.payload!}`,
          severity: "error",
        })
      );
    } else {
      dispatch(showAlert({ message: "Success", severity: "success" }));
    }
    setTermId("");
    setIsDeleteClick(false);
  };
  return (
    <>
      {loading ? (
        <ComponentLoadingSpinner height="90vh" />
      ) : (
        <Container maxWidth={"xl"} sx={{ height: "90vh" }}>
          <Grid container columnSpacing={2} spacing={2}>
            <Grid item xs={12} height={"90vh"} container rowGap={2}>
              <Grid item container justifyContent={"space-between"} textAlign={"center"}>
                <Typography variant="h5">Term & Holidays</Typography>
                <Fab
                  size="small"
                  variant="extended"
                  aria-label="add"
                  color="success"
                  sx={fabBox}
                  onClick={() => setOpenSchoolModal(true)}
                >
                  <CalendarMonthIcon sx={{ mr: 1 }} />
                  Create Term
                </Fab>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ position: "relative", border: "1px solid #ebebeb" }}
                >
                  <Table aria-label="term table" size="small">
                    <TableHead
                      sx={{
                        th: { fontWeight: 500, backgroundColor: "#14e6dd" },
                      }}
                    >
                      <TableRow>
                        <TableCell>Term Start</TableCell>
                        <TableCell>Term End</TableCell>
                        <TableCell>Public Holidays</TableCell>
                        <TableCell>School Holidays</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {savedTermHolidays &&
                        savedTermHolidays.map((row) => (
                          <TableRow key={row._id}>
                            <TableCell component="th" scope="row">
                              {new Date(row.termStart).toDateString()}
                            </TableCell>
                            <TableCell>{new Date(row.termEnd).toDateString()}</TableCell>
                            <TableCell>
                              {groupedHolidays[new Date(row.termStart).getFullYear()]?.holidays.length ?? 0}
                            </TableCell>
                            <TableCell>{row.holidays.length}</TableCell>
                            <TableCell>
                              {row.holidays.length +
                                (groupedHolidays[new Date(row.termStart).getFullYear()]?.holidays.length ?? 0)}
                            </TableCell>
                            <TableCell width={"90px"}>
                              <Box display={"flex"} justifyContent={"space-between"}>
                                <Button
                                  variant="contained"
                                  startIcon={<EditIcon />}
                                  onClick={() => editRowTerm(row._id!)}
                                  size="small"
                                  fullWidth
                                  sx={{
                                    mr: 1,
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  sx={{
                                    color: "white",
                                    borderColor: "red",
                                    backgroundColor: "red",
                                  }}
                                  variant="contained"
                                  disabled={termId === row._id && isDeleteClicked}
                                  startIcon={<DeleteIcon />}
                                  onClick={() => deleteRowTerm(row._id!)}
                                  size="small"
                                  fullWidth
                                  color={undefined}
                                >
                                  {termId !== row._id || !isDeleteClicked ? (
                                    "Delete"
                                  ) : (
                                    <CircularProgress size={16} sx={{ color: "red" }} />
                                  )}
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item container>
                <Typography variant="h5">Bookings</Typography>
                <Divider sx={{ width: "100%" }} />
              </Grid>
              <Grid item container justifyContent={"space-between"}>
                <Grid item container xs={6}>
                  <Fab
                    size="small"
                    variant="extended"
                    aria-label="add"
                    color="success"
                    sx={{
                      ...fabBox,
                      backgroundColor: "purple",
                      color: "white",
                      marginLeft: 0,
                    }}
                    onClick={() => {
                      setOpenMonthBreakfastClubBooking((prev) => !prev);
                    }}
                  >
                    <WbSunnyIcon sx={{ mr: 1, color: "yellow" }} />
                    {format(new Date(), "MMMM")}'s Breakfast Bookings
                  </Fab>
                  <Fab
                    size="small"
                    variant="extended"
                    aria-label="add"
                    color="success"
                    sx={{
                      ...fabBox,
                      backgroundColor: "purple",
                      color: "white",
                      // marginLeft: 0,
                    }}
                    onClick={() => {
                      setOpenRoaster((prev) => !prev);
                    }}
                  >
                    <PermContactCalendarIcon sx={{ mr: 1, color: "yellow" }} />
                    {format(new Date(), "MMMM")}'s Roaster
                  </Fab>
                </Grid>
                <Grid item>
                  <Fab
                    size="small"
                    variant="extended"
                    aria-label="add"
                    color="success"
                    sx={fabBox}
                    onClick={() => setCurrentMonthBooking(true)}
                  >
                    <EventAvailableIcon sx={{ mr: 1 }} />
                    {format(new Date(), "MMMM")}'s Afternoon Bookings
                  </Fab>
                </Grid>
              </Grid>

              <Grid item container minHeight={"60vh"}>
                <CustomizedDataGrid
                  rows={rows}
                  columns={columns}
                  loading={loading}
                  getRowId={(row) => row._id}
                  rowHeight={35}
                  headerHeight={38}
                  pageSize={28}
                  rowsPerPageOptions={[28]}
                  // disableColumnMenu
                  autoHeight
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <Suspense fallback={<PageLoadingSpinner />}>
        {openModal && <TermTimeSheet open={openModal} handleClose={handleClose} editTerm={editTerm} termId={termId} />}
      </Suspense>
      <Suspense fallback={<PageLoadingSpinner />}>
        {openPupilModal && <PupilBooking id={pupilId} handleClose={handlePupilModalClose} open={openPupilModal} />}
      </Suspense>
      <Suspense fallback={<PageLoadingSpinner />}>
        {openCurrentMonthBooking && (
          <CurrentMonthBooking handleClose={handleCurrentMonthBookingClose} open={openCurrentMonthBooking} />
        )}
      </Suspense>
      <Suspense fallback={<PageLoadingSpinner />}>
        {openMonthBreakfastClubBooking && (
          <BreakfastClubAttendance open={openMonthBreakfastClubBooking} handleClose={handleBreakfastClubAttendance} />
        )}
      </Suspense>
      <Suspense fallback={<PageLoadingSpinner />}>
        {openRoaster && <StaffRoaster open={openRoaster} handleClose={handleCloseRoaster} />}
      </Suspense>
    </>
  );
}
